import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Contemporary Impacts of Confederacy and Jim Crow',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/d4pR0Nk0ugo',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      The legacies of the Confederacy and Jim Crow are still widely felt by
      residents in the South, but the degree to which there is contemporary
      impact is not agreed upon.
    </p>
    <p>
      Though many agree that the hate-filled history of the Confederacy and the
      Civil War in the South continues to be a visible source of tension,
      residents do not always see value in revisiting it. Many in Montgomery and
      Richmond, both former capitals of the Confederacy, cited their community
      history related to the Civil Rights Movement as a source of pride, while
      others said discussions focused too much on negativity.{' '}
    </p>
    <p>
      We also saw time and again that Confederate symbols and monuments, and
      political rhetoric about the identity of a place, often prioritize and
      commemorate a one-sided history that disregards the lasting institutional
      effects of racism. The unwillingness of many (particularly white) people
      to confront a hate-filled history—including its close ties to heritage,
      and its lasting impact on modern institutions—has hindered the development
      of more contemporary Southern identities associated with openness and
      reconciliation.
    </p>
    <p>
      Groundbreaking research from the Southern Poverty Law Center (SPLC) has
      noted that much of this may stem from the fact that our schools are
      failing to teach the hard history of African enslavement.Only 8 percent of
      high school seniors surveyed can identify slavery as the central cause of
      the Civil War. Two-thirds (68 percent) don’t know that it took a
      constitutional amendment to formally end slavery. Forty percent of
      teachers believe their state offers insufficient support for teaching
      about slavery.
    </p>
    <p>
      SPLC has also reported on the impact of Confederate statues and memorials
      that dot the country’s landscape. Many Americans have different
      understandings of history, particularly the values and hopes of the
      Confederacy. The falsehoods behind the “Lost Cause” must be counteracted
      with factual narratives of history. While more than 100 monuments and
      other Confederate symbols have been removed in 22 states, since June 2015,
      most prominently in New Orleans, there are more than 1,700 symbols of the
      Confederacy in public spaces including school names, statues and
      monuments. To this day, the Confederate flag maintains a publicly
      supported presence in at least five Southern states, and 11 states have 23
      Confederate holidays or observances in their state codes — nine of which
      were paid holidays in 2018, according to SPLC.
    </p>
    <Quote
      quote="To understand the South and southern history, you have to understand the history of race, of slavery, of the Civil War, of reconstruction, of Jim Crow, of the Civil Rights Movement. It’s still around us. I think we had talked earlier about Faulkner. You know, the past is not dead. It’s not even the past."
      person="White male, 52"
      title="Montgomery"
    />

    <Quote
      quote="I just think that people get angry about the Civil War. And there’s no common communication or trying to get together to talk to each other about it... I know they wanna tear down monuments because people think that it’s offensive to African Americans. But I don’t think we need to tear them down and forget how far we’ve come with history."
      person="White female, 53"
      title="Richmond"
    />

    <Quote
      quote="I think a lot of the black people here are still angry over what happened. It didn’t happen to them, the white people here didn’t do it to them, but they’re still angry about what happened to their ancestors. And they’re...they dislike white people because of it a lot of times."
      person="White female"
      title="Montgomery"
    />

    <Quote
      quote="Making amends is part of our culture, accepting responsibility for us doing wrong is part of our culture. Why can’t we just apply that to slavery and white supremacy?"
      person="White male, 40"
      title="Montgomery"
    />
  </Modal>
);
